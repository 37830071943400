<template>
  <div class="w-full grid grid-cols-1 terms-component">
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="src"
      :page="i"
      style="display: inline-block; width: 100%"
    ></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
var pdfDocument = pdf.createLoadingTask('terms.pdf');

export default {
  name: 'Terms',
  components: {
    pdf,
  },
  data() {
    return {
      src: pdfDocument,
      numPages: 0,
    };
  },
  mounted() {
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages;
    });
  },
};
</script>

<style scoped>
.terms-component {
  min-height: 100vh;
}
</style>
